

/* OurClients */

.our-clients {
    text-align: center;
    padding: 2rem;
    background-color: #f8f9fa;
  }
  
  .our-clients h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .clients-grid {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 1.5rem;
    justify-content: center; /* Centers the grid in the container */
  }
  
  .client-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
    max-width: 200px;
    text-align: center;
    flex: 1 1 calc(25% - 1.5rem); /* Default: 4 cards per row */
  }
  
  .client-card:hover {
    transform: translateY(-5px);
  }
  
  .client-logo {
    width: 80px;
    height: auto;
    margin-bottom: 0.5rem;
  }
  
  .client-name {
    font-size: 1rem;
    color: #555;
    font-weight: bold;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .client-card {
      flex: 1 1 calc(33.33% - 1.5rem); /* 3 cards per row on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .client-card {
      flex: 1 1 calc(50% - 1.5rem); /* 2 cards per row on small screens */
    }
  }
  
  @media (max-width: 480px) {
    .client-card {
      flex: 1 1 100%; /* 1 card per row on extra small screens */
    }
  }
    

/* Services */
.services {
    text-align: center;
    padding: 1.5rem;
    background-color: #f8f9fa;
  }
  
  .services h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .services-grid {
    display: grid;
    gap: 1.0rem;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr); /* Default: 3 cards per row */
  }
  
  @media (max-width: 1024px) {
    .services-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 cards per row on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr; /* 1 card per row on small screens */
    }
  }
  
  .service-card {
    
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
    max-width: 350px;
    text-align: center;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .service-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .service-content {
    padding: 1rem;
  }
  
  .service-content h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .service-content p {
    font-size: 0.9rem;
    color: #666;
  }

  /* AboutUs */

/* Home */
.home-para1 {
  margin: 10px 100px; /* Default margin for larger screens */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.home-para1 > div {
  flex: 1;
  min-width: 200px;
  margin: 10px; 
}

.sp {
  color: #007bff;
}

#pic {
  margin: 20px auto;
  display: block;
  max-width: 90%; 
  height: auto; 
}

.hp1 {
  color: #007bff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-para1 {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items for better layout */
    margin: 20px; /* Add more space for smaller screens */
  }

  #pic {
    margin: 20px auto;
    height: 200px; 
    max-width: 100%; 
  }

  .home-para1 > div {
    margin: 15px auto; /* Slightly increase spacing */
  }

  h1.hp {
    font-size: 1.5rem; /* Reduce font size for better readability */
    text-align: center; /* Center align headings */
  }

  p {
    font-size: 1rem; /* Adjust font size for smaller screens */
    line-height: 1.6; /* Increase line spacing for better readability */
    text-align: justify; /* Justify text for clean alignment */
    padding: 0 15px; /* Add padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .home-para1 {
    padding: 10px; /* Reduce overall padding */
    align-items: center; /* Center items */
  }

  #pic {
    height: 150px; 
    margin-bottom: 20px; /* Add spacing below image */
  }

  .home-para1 > div {
    margin: 10px auto; 
  }

  h1.hp {
    font-size: 1.2rem; /* Further reduce font size */
    text-align: center;
  }

  p {
    font-size: 0.9rem; /* Adjust text size for readability */
    line-height: 1.4; 
    padding: 0 10px; 
  }
}
@media (max-width: 480px) {
  h4 {
    font-size: 1.2rem;
    margin: 5px 0;
  }

  h4 strong {
    color: blue; /* Example of adapting styles for smaller screens */
  }
}